import React from 'react'
import { useStaticQuery, graphql, Link } from "gatsby"
import Layout from "../layouts"
import '../styles/events.scss';
import CTAList from '../components/cta-list/ctaList';
import { Helmet } from "react-helmet"
import 'uikit/dist/css/uikit.css'



const Events = () => {


    const data = useStaticQuery(graphql`
        {
            strapiEventsPage {
                Title
                SEOTitle
                SEODescription
              }
            
            allStrapiEvents(sort: {order: DESC, fields: FromDate}) {
                nodes {
                  Image{
                    localFile{
                        childImageSharp {
                            fluid(quality: 90, maxWidth: 1900) {
                              ...GatsbyImageSharpFluid_withWebp
                            }
                            gatsbyImageData(
                              width: 1900
                              placeholder: BLURRED
                              formats: [AUTO, WEBP]
                            )
                          }
                    }
                  }
                  FromDate(formatString: "MMMM D YYYY")
                  Title
                  Slug
                  Description
                  ToDate(formatString: "MMMM D YYYY")
                }
              }

            allFile {
            edges {
              node {
                publicURL
                id
                name
                childImageSharp {
                  fluid(quality: 90, maxWidth: 1900) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                  gatsbyImageData(
                    width: 1900
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                  )
                }
              }
            }
          }

        }


    `)

    const contentsList = [];
    const today = new Date();

    function getImgUrl(localFile___NODE) {

        var imgUrl = { imgName: null, basicUrl: null, fluid: null, gatsbyImageData: null }

        data.allFile.edges.map((item, index) => {

            if (item.node.id === localFile___NODE) {
                // if(item.node.publicURL.endsWith(".svg")){
                //   imgUrl.basicUrl = item.node.publicURL
                // } else {
                //   imgUrl.fluid = item.node.childImageSharp.fluid
                //   imgUrl.basicUrl = item.node.publicURL
                // }
                imgUrl.imgName = item.node.name ? item.node.name : null
                imgUrl.basicUrl = item.node.publicURL ? item.node.publicURL : null
                imgUrl.fluid = item.node.childImageSharp ? item.node.childImageSharp.fluid : null
                imgUrl.gatsbyImageData = item.node.childImageSharp ? item.node.childImageSharp.gatsbyImageData : null

                // imgUrl = item.node.publicURL
            }
            return ("")

        })
        // console.log(imgUrl)
        return (imgUrl)
    }

    return (

        <Layout SEOTitle={data.strapiEventsPage.SEOTitle} SEODescription={data.strapiEventsPage.SEODescription} footerDisclaimer={data.strapiEventsPage.footerDisclaimer}>

            {/** List of meta properties coming from strapi that applies only for the homepage */
                <Helmet>
                    {data.strapiEventsPage.MetaTags?.map((item, index) => {
                        return (
                            <>

                                <meta key={`meta-key-` + index} name={item.MetaName} content={item.MetaContent} />
                            </>
                        )
                    })}
                </Helmet>
            }
            <div className="events-page">
                <div className="events-container">
                    <div className="events-content">
                        <div className="events-header">
                            <h1>Previous events</h1>
                            <Link to="/events" className="link-to-events next-event">next events »</Link>
                        </div>
                        <div className="events-body">
                            {
                                data.allStrapiEvents.nodes ? data.allStrapiEvents.nodes.filter(item => {
                                    return new Date(item.ToDate) < today;
                                }).map((item, index) => {

                                    contentsList.push({
                                        Title: item.Title,
                                        URL: item.URL,
                                        URLLabel: item.URLLabel,
                                        Image: item.Image,
                                        FromDate: item.FromDate,
                                        ToDate: item.ToDate,
                                        Alt: item.Alt,
                                        Slug: item.Slug,
                                        Description: item.Description,
                                        DescriptionCharLimit: item.DescriptionCharLimit,
                                        CardType: 'event'
                                    })

                                })

                                    : ""
                            }
                            {
                                contentsList ?
                                    <CTAList
                                        contents={contentsList}
                                    ></CTAList>
                                    : ""

                            }
                        </div>
                    </div>
                </div>
            </div>

        </Layout>

    )

}

export default Events